import React                from 'react'
import Img                  from "gatsby-image"
import { map }              from 'lodash-es'
import { FaClock }          from 'react-icons/fa'
import {
  Container,
}                           from 'react-bootstrap'
import Alert                from 'react-bootstrap/Alert'
import RawHtml              from 'entrada-ui/RawHtml'
import Banner               from 'components/Banner'
import BookContact          from 'components/BookContact'
import Link                 from 'components/Link'
import TourCards            from 'components/TourCards'
import Seo                  from 'components/Seo'
import PhoneContactBlock    from 'components/PhoneContactBlock'
import PageTitle            from 'components/PageTitle'
import styles               from './styles.module.scss'

import { FIXED_FRAGMENT, FLUID_FRAGMENT } from 'utils/constants.js'

const TourCard = TourCards.CardWp

const Destination = ({ pageContext: { data } }) => {
  const {
    ToursWithCombos,
    TourCombos: {
      siteMetadata,
      title,
      content,
      acf: {
        featured_image: {
          localFile: {
            childImageSharp: {
              fluid
            }
          }
        },
        sub_title
      },
      yoast_meta
    }
  } = data



  return (
    <>
      <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
      <PageTitle title={title} isDestination={false} />
      <Container className={styles.containerSmallNoGutter}>
        <Img fluid={fluid} className={styles.heroImage} />
      </Container>
      
      <div className={styles.tourComboBody}>
      <Container>
        <h3>{sub_title}</h3>
        <RawHtml html={content} />
    
        <TourCards aside={true}>
          {map(ToursWithCombos, (tour, idx) => {
             return (<TourCard
              key={idx}
              {...tour}
            />)
          }
          )}
        </TourCards>

        <PhoneContactBlock />

      </Container>
      </div>

    </>
  )
}


export default Destination
